import React, { useEffect } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import hero from "../../assets/hero/hero04.jpg";
import { useOutletContext } from "react-router-dom";

export const Location = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/location");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="LOCATION" backgroundImage={hero} />
      <div className="single-text-box container-style">
        <div className="content-sizing single-content-box">
          <h2>Location, Location, Location</h2>
          <p>
            We are so excited to be making Manitoba our home base and making
            this project a reality. We understand that everyone is eager for
            information on this venture. The future home of DreamScape One will
            be announced in the not too distant future at our public release.{" "}
          </p>
          <p>
            DreamScape is a privately funded project; however projects of this
            size are not built without your support. DreamScape requires the
            support of all Manitobans, Canadians, as well all levels of
            government. We are hard at work ensuring that the land assessments
            and studies satisfy our concern of minimal environmental impact and
            growth potential, which is a necessary and time -consuming process.
            Once this step is complete, we will be publicly announcing this
            exciting news.{" "}
          </p>
          <p>
            DreamScape will be updating information regularly and as quickly as
            possible on our website. We are committed to building something
            monumental in all its Manitoban grandeur and style. Make sure you
            check our website www.dreamscapewinnipeg.com and Facebook regularly
            to see these exciting additions.{" "}
          </p>
          <p>
            Our sincerest thanks for your dedicated support! We hope to see you
            all enjoying our facilities in the near future!{" "}
          </p>
          <p>Your DreamScape One Team!</p>
        </div>
      </div>
    </>
  );
};
