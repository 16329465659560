import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/hero21.jpg";
import { useOutletContext } from "react-router-dom";
import { condoImages as image } from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';


export const Condo = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="PREMIER LIVING" subText="A vibrant, summer-like atmosphere all year-round" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Your new community awaits you</h2>
          <AutoSlideshow image={image} />
          <p>
            DreamScape One’s premier living promises a vibrant and summer-like atmosphere every day of the year. Embodied with luxury, comfort and relaxation in mind, our premium condominiums are designed as a perfect choice that meets your day to day needs all in one place. Offering direct access to all amenities and conveniences, along with beautiful panoramic views of the enclosed environment makes this a top choice all around.
          </p>
          <p>
            Ideally situated on our manmade mountains, with a cascading flow overlooking the golf courses and green space areas, each corner is designed for a different world theme, along with direct access to parking.

          </p>

          <p>
            Bringing the tropics to you year-round in our natural setting will invigorate anyone for a walk or run in our green spaces in the middle of winter. With class and elegance in mind, each unit showcases the finest quality of craftsmanship, interior decor and sound proofing.
          </p>
          <p>
            Ensuring the amenities of a local neighborhood is a critical need in creating the community of the future, hence the design and inclusion of The International Food Market. Essential to the residents and locals, the market will include shops like bakeries, butchers, fruit and vegetable vendors, chocolate shops, wine and cheese stores, fish markets and ethnic food shops that represents cultures from around the globe.
          </p>
          <p>
            So whether you desire the tranquility and peacefulness of silence or a vibrant, warm atmosphere, DreamScape’s premier residential living is where you want to be.
          </p>
        </div>
      </div>
    </>
  );
}


