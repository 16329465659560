import "./style.css";

export const BackToTop = (prop) => {
  return (
    <button
      className={prop.backToTopButton ? "show-back-to-top back-btn" : "back-btn"}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <i className="bi bi-chevron-up"></i>
    </button>
  );
};
