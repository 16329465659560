import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/hero10.jpg";
import { useOutletContext } from "react-router-dom";
import { amusementImages as image } from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';


export const Amusement = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="AMUSEMENT PARK" subText="DreamScape One Amusement Park will be located in our climate controlled environment under our superstructure roof." backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Retail</h2>
          {/* <script src="https://www.youtube.com/s/player/bc657243/www-embed-player.vflset/www-embed-player.js" nonce=""></script>
           */}
          {/* <h1>YOUTUBE VID</h1> */}
          <p>
            DreamScape will bring Manitoba a warm, colorful and exciting summer like atmosphere year-round. We will spare no expense as we take you back to the turn of the century when Winnipeg once housed the largest amusement park center in Canada. Fun filled with thrills and chills, the excitement will take you to new heights with state of the art mega coasters and thrill rides.
          </p>
          <p>
            Complimented by the world’s best manufacturers and ride engineers, with safety as our number one concern and excitement being our number one goal, be prepared to be catapulted into the rides of your life!
          </p>
          <p>
            From the classic wooden coasters, water rides and games to the futuristic extreme coasters, it can all be found in our Amusement Park filled with family fun and thrills for all to enjoy. Explore our different themes and worlds as DreamScape works with the world’s most amazing Amusement Park companies to bring you the best rides and entertainment.
          </p>
          <AutoSlideshow image={image} />
          <p>
            A Park For All Seasons:
          </p>

          <ul className="simple-list">
            <li>Experience <strong>THE</strong> best rides, games, shows and much more</li>
            <li>Buy your tickets ahead of time and <strong>VIRTUALLY</strong> book yourself in any line</li>
            <li>Half day, full day and multi passes available to allow more flexibility</li>
            <li>Cutting edge technology and new age designs</li>
          </ul>
        </div>
      </div>
    </>
  );
}


