import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/hero17.jpg";
import { useOutletContext } from "react-router-dom";
import image from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';

export const Winterpark = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="WINTER PARK" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Winter, snow and outdoor fun is a big part of our Canadian culture. At DreamScape we want to showcase we are Canadians and proud of our culture.</h2>
          <p>Located just outside of our climate-control super structure will be an incredible winter park for those that loves the outdoors in winter. </p>
          <p> We pride ourselves on offering you the best in class for fun things to do with your time. So naturally at DreamScape we went all out to provide you mountains for skiing, rolling hills for cross-country and toboggans, trails for your snowmobiles, ski-jumps with aerial lifts and of course large size skating rinks.
          </p>
          <p>Complimented with rental centers for all your equipment needs and concession stands thru-out for your hot chocolate, coffees and espresso; we will keep you energized and warm for your outdoor fun. Your comfort and enjoyment is very important to us so you can always find a place to sit or warm-up in our park, or just cozy-up at our controlled natural fire pits.
          </p>
          <p>Our professionally designed winter landscape will not only be aesthetically pleasing but will be designed to challenge you on different levels in every sport. Special consideration is being put into every curve, contour and slope to exhilarate and thrill you; we want you coming back because you want to. Whether it’s day or night at DreamScape, you can enjoy your favorite outdoor sports as all our trails will be lit up at night with lighted borders in the snow.
          </p>
        </div>
      </div>




    </>
  )
}
