import React from 'react'
import "./style.css";
import { Link } from 'react-router-dom';
export const Footer = () => {
  return (
   <footer>
    <div className='content-sizing footer-content'>
      <Link>FOLLOW @DREAMSCAPE_204</Link>
      <p>&copy; 2024 Dreamscape One</p>
    </div>
   </footer>
  )
}
