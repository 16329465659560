import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/hero/hero22.jpg";
import { useOutletContext } from "react-router-dom";
import { hotelnewImages as image } from './image';
import { Slideshow } from '../../components/Slideshow/Slideshow';


export const Hotels = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="HOTELS" subText="Our two luxury boutique hotels will feature approximately 1000 rooms per hotel furnished with upscale amenities and one of a kind technology." backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Best Hotels in Manitoba</h2>
          <Slideshow image={image} />
          <p>
            DreamScape One has two luxury boutique hotels located under its superstructure roof accommodating a minimum of 1000 rooms each. Furnished with modern décor each offers very different experiences for the corporate or leisure traveler with the latest in hospitality, technology, and services. With breathtaking views of the surrounding landscapes, guests can truly enjoy the full experience of an all in one destination with each hotel offering its own unique theme and culture.
          </p>
          <p>
            The first hotel will be a luxury executive property catered to the corporate client. This hotel provides true personal attention, the newest and best in hospitality technology, well-appointed upscale amenities, beautifully enhanced accommodations, a luxurious mineral spa and memorable culinary experiences. Some of the features exclusive to this hotel will include:
          </p>
          <ul className="simple-list">
            <li>Personalized concierge service</li>
            <li>Olympic size swimming pool with steam room, hot tub and lounge chairs</li>
            <li>An all-inclusive mineral spa</li>
            <li>Expansive fitness facility featuring the top tier equipment with overlooking views of DreamScape</li>
            <li>Embroidered bathrobes in all guest rooms</li>
            <li>Complimentary Wired and Wireless High Speed Internet</li>
            <li>2 upscale restaurants featuring sculptured menus from the top local chefs</li>
            <li>Some rooms and suites will feature large balconies with incredible views of DreamScape</li>
          </ul>
          <p>
            The second hotel is family oriented with complimentary deluxe continental breakfast, fine dining restaurants, children’s theme rooms, expanded executive suites and extended stay accommodations with full kitchenettes. Some of the features exclusive to this hotel will include:
          </p>
          <ul className="simple-list">
            <li>Long term stay rooms including executive suites with full kitchenettes and large living room areas</li>
            <li>Kids theme suites with various themes and layouts</li>
            <li>2 swimming pools within the hotel that will feature waterfalls, slides, splash pads and other activities</li>
            <li>Complimentary hot continental breakfast served every morning</li>
            <li>Daycare services available on site</li>
            <li>Complimentary high speed WIFI in all guest rooms</li>
          </ul>
          <p>
            Nestled in the heart of DreamScape, the hotels will be located in the vicinity of the water park, golf course, convention centre, amusement park, city sized park with running trails/biking trails and so much more.
          </p>

          <p><b>DreamScape One convention center will be bridged between the hotels, allowing the opportunity to house large conventions, tradeshows and events all under the same roof. With over 300,000 sq. feet of convention Centre space, with different sized convention halls, lecture rooms and meeting rooms along with executive boardrooms, we will truly be a one stop shop for the corporate and leisure market.
          </b></p>
          <p><b>Enjoy a relaxing and extravagant experience in an unforgettable landscape. DreamScape One’s hotels will surely bestow you with an everlasting wonderful experience.</b></p>
        </div>
      </div>
    </>
  );
}


