import "./style.css";
import React from 'react'
import { useState, useEffect } from 'react';
import logo from "../../assets/logo.png";
import { Link, useOutletContext } from "react-router-dom";
import { Reveal } from "../../components/Reveal/Reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import images from "./image";
import { EffectFade, Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ModalVideo from "react-modal-video";
export const Project = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="main-banner-section">
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="eexL4n_p93k"
          onClose={() => setOpen(false)}
        />


        <Swiper
          spaceBetween={30}
          effect={"fade"}
          // loop={true}
          autoplay={{
            delay: 4000,
          }}
          modules={[EffectFade, Autoplay]}
          className="mySwiper"
        >
          {images.map((el, i) => {
            return (
              <SwiperSlide key={i}>
                <img class="slide-img" src={el} />
              </SwiperSlide>
            );
          })}
          <div className=" banner-home">
            <Reveal>
              <img src={logo} alt="Dreamscape logo" />
            </Reveal>
            <Reveal delay={0.1}>
              <div className="button-banner">
                <p>
                  A new and exciting environmentally responsible project that
                  brings together the best in entertainment, fun and
                  hospitality.
                </p>
                <h2>YEAR ROUND ALL UNDER ONE ROOF!</h2>
                <button onClick={(e) => { setOpen(true) }}><i className="bi bi-play"></i> PLAY PROMO</button>
              </div>
            </Reveal>
          </div>
        </Swiper>


      </section>

      <section className="container-style page-spacing">
        <div class="content-sizing project-content-page">
          <i>
            This website is presently in the process of being revamped. Please
            bear with us as we continue the process to provide you more enhanced
            information.
          </i>
          <i>
            Visualizations and information shown on this website are for
            expression and illustrations only; may be subject to change.
          </i>

          <h1 class="sec-title">The Project</h1>
          <p class="sec-subtext">
            Focused on our environment and climate issues DreamScape One is a
            vision of the way forward for society based on renewable energy
            sources, sustainability and a carbonless footprint. At a footprint
            of 500 acres for the enclosed environment with a minimum ceiling
            height of 160 feet, DreamScape (short form) is building the mega
            structures of the future.
          </p>

          <p>
            The DreamScape business model is based on everything under one roof,
            protected from the outside elements year-round. The concept is
            unique just on its sheer size and the aspects of its
            self-sustainability before adding in our advance marketing campaign
            and futuristic technology. For all intents and purposes DS1
            (acronym) is being designed as a Theme Park complimented with the
            right balance of added features, which will help to offset the high
            cost of building such a structure. Theme Parks have an established
            record of longevity and profiting in any economy, especially being
            year-round. DreamScape is about people; we can accommodate a large
            amount of residency in addition to a tremendous volume of
            vacationers/tourists making this a global destination. The time has
            come in our evolution cycle to provide enhanced, safe communities
            that confronts our environment and climate change issues with a
            positive impact.
          </p>
          <p className="heavy-text">
            DreamScape is an exciting environmentally responsible project that
            brings together the best in entertainment, fun and hospitality - a
            year round, world class family-themed facility.
          </p>

          <center>
            <h1>Features</h1>
          </center>
          <ul className="list-left-project">
            <li>
              Two Luxury, Boutique style hotels with accommodations for over
              2000 guests plus executive suites
            </li>

            <li class="grid-tem ftr">
              Comprehensive Convention Center with over 300,000 square feet of
              banquet space.
            </li>

            <li class="grid-tem ftr">
              18 Hole Championship Golf Course for avid golfers
            </li>

            <li class="grid-tem ftr">
              9 Hole Family and Friends Recreation Golf Course
            </li>

            <li class="grid-tem ftr">
              Regulation size Ice Rinks, city size Recreation Parks and walking
              trails
            </li>

            <li class="grid-tem ftr">
              World-Class Symphony/Concert Hall Amphitheatre with a retractable
              roof
            </li>

            <li class="grid-tem ftr">
              Offices, Retail, brand-name outlets and boutique stores in an
              international themed shopping center
            </li>

            <li class="grid-tem ftr">
              Cinemas and Drive-In Movie Theatres, Fine Dining Restaurants, Food
              Courts, Premium Nightlife and Entertainment Zone
            </li>

            <li class="grid-tem ftr">
              Water Park featuring mind-bending slides, rides and wave pools,
              walkways, grotto and gardens
            </li>

            <li class="grid-tem ftr">
              Amusement Park featuring the newest in thrill rides, amazing
              roller coasters, 4D flying theatres, the gigantic Ferris wheel
            </li>

            <li class="grid-tem ftr">
              An all-inclusive outdoor Winter Park - ski, snowboard, tubing and
              other outdoor activities
            </li>

            <li class="grid-tem ftr">
              Sports Complex with year-round venues for each major recreational
              activity: baseball, soccer, basketball, tennis and ultimate
              frisbee
            </li>

            <li class="grid-tem ftr">
              An International Food Market featuring local vendors, farmers
              market and grocery stores.
            </li>

            <li class="grid-tem ftr">
              Condominium Complexes with over 1500 Condominiums for residential
              living and vacationing
            </li>
          </ul>
          <div class="sec-subtext">
            <p>
              Five Hundred (500) acres of land completely enclosed, climate and
              temperature controlled under the superstructure roof makes this a
              marvel of engineering. Enhanced with an aerial view by cable cars
              DS1 is primed to become a Canadian premier attraction. DreamScape
              is a reality, the technologies have arrived and the support is
              growing; the timing is right.
            </p>

            <p>
              DreamScape will become a premier attraction as a global
              destination, bringing tourism from all over the world to Canada.{" "}
            </p>

            <p>
              DreamScape's retail shopping and restaurant opportunities will
              open the door for more international companies to expand in the
              Canadian Market. These discussions have already begun and ongoing.
              We are very excited to share with you in the near future the
              corporate retail companies we will be working with on this amazing
              project.
            </p>

            <p>
              Manitoba, a province rich in culture, heritage and natural
              resources is now challenged by global climate change and a public
              desire for sustainable living and most importantly "long-term
              growth". DreamScape One is about sustainable living, the
              environment and the future of Manitoba and Canada. It is designed
              with community, family and fun in mind, as a major destination
              spot to bring people together in the heart of North America. With
              its International Marketing Program, DreamScape will bring people
              from around the world to its global destination.
            </p>
          </div>
          <ul class="sec-list clearfix">
            <li className="ftr hotel-list">
              <Link to="hotels">
                <h2 class="ftr-title">Hotels</h2>
                <span class="ftr-sub">
                  Two luxury boutique hotels featuring the latest in technology
                  and hospitality amenities.
                </span>
              </Link>
            </li>
            <li className="ftr living-list">
              <Link to="condo">
                <h2 class="ftr-title">Intraurban Living</h2>
                <span class="ftr-sub">
                  Condominiums featuring access to local food markets, running
                  trails, fitness center and all the amenities and luxuries of
                  DreamScape One.
                </span>
              </Link>
            </li>
            <li className="ftr retail-hub">
              <Link to="mall">
                <h2 class="ftr-title">The Retail Hub</h2>
                <span class="ftr-sub">
                  Offices, business center, shopping center, cinemas and
                  drive-in, fine dining restaurants and food courts.
                </span>
              </Link>
            </li>
            <li className="ftr amusement-hub">
              <Link to="amusement">
                <h2 class="ftr-title">Amusement</h2>
                <span class="ftr-sub">
                  Year round Amusement park featuring exciting roller coasters,
                  thrilling rides, family and kids rides and motion theater
                  experiences.
                </span>
              </Link>
            </li>
            <li className="ftr water-hub">
              <Link to="waterpark">
                <h2 class="ftr-title">Water Park</h2>
                <span class="ftr-sub">
                  DreamScape One Water Park will be open year round offering
                  wild water themed excitement under one climate controlled
                  roof.
                </span>
              </Link>
            </li>
            <li className="ftr winter-hub">
              <Link to="winterpark">
                <h2 class="ftr-title">Winter Park</h2>
                <span class="ftr-sub">
                  Ice-skating, skiing, tubing and lots more for outdoor fun and
                  recreation.
                </span>
              </Link>
            </li>
            <li className="ftr theater-hub">
              <Link to="theater">
                <h2 class="ftr-title">Amphitheatre</h2>
                <span class="ftr-sub">
                  World-class venue for Symphonies / Concerts / Live Plays /
                  Arts and Culture Events.
                </span>
              </Link>
            </li>
            <li className="ftr drive-hub">
              <Link to="drivein">
                <h2 class="ftr-title">Drive-In Theatre</h2>
                <span class="ftr-sub">
                  DreamScape One, will be home to two unique drive-in movie
                  theatres, featuring new Hollywood hits and classic titles year
                  round.
                </span>
              </Link>
            </li>
            <li className="ftr gold-hub">
              <Link to="golf">
                <h2 class="ftr-title">Golf Course</h2>
                <span class="ftr-sub">
                  A full 18 hole professional golf course and a 9 hole
                  recreational golf course open year round.
                </span>
              </Link>
            </li>
            <li className="ftr Premium-hub">
              <Link to="https://web.archive.org/web/20220526121347/http://dreamscapewinnipeg.com/adultzone">
                <h2 class="ftr-title">Premium Space and Nightlife Zone</h2>
                <span class="ftr-sub"></span>
              </Link>
            </li>
            <li className="ftr sport-hub">
              <Link to="skatingrink">
                <h2 class="ftr-title">SportsPlex</h2>
                <span class="ftr-sub">
                  Basketball courts, soccer fields, baseball diamonds, tennis
                  courts and a fitness center for year-round sports activities.
                </span>
              </Link>
            </li>
            <li className="ftr green-hub">
              <Link to="green">
                <h2 class="ftr-title">Green Space</h2>
                <span class="ftr-sub">
                  Picturesque city-sized park with walking trails, bike trails,
                  children’s play areas, volleyball courts, picnic areas, flower
                  gardens and a dog park.
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <div style={{ overflow: 'hidden', resize: 'none', maxWidth: '100%', width: '100%', height: '500px' }}>
        <div id="google-maps-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}>
          <iframe style={{ height: '100%', width: '100%', border: '0' }} title="Google Maps" src="https://www.google.com/maps/embed/v1/place?q=winnipeg+canad&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
        </div>

      </div>
    </>
  );
};
