
import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/hero23.jpg";
import { useOutletContext } from "react-router-dom";
import image from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';
export const Green = () => {

  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="GREEN SPACE" subText="" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Green Space</h2>
          <p>
            The Sportsplex located within DreamScape will be the first of its kind in Manitoba and will surpass any complex like it in Canada. Year-round, you will have the opportunity to play baseball, soccer (on natural grass), basketball, ice skating, run, exercise, train, compete in mixed martial arts events and so much more. Getting into peak physical condition will seem a lot easier at this expansive fitness facility. We will keep you motivated in winter, with our beautiful landscaped scenery of green parks, trees, ponds, trails, bridges and waterfalls inside DreamScape’s climate controlled superstructure. You will want to come back and shake off those cold winter blues.
          </p>
          <p>
            Yoga, dancing and gymnastics will be some of the many programs offered in our spectacular Signature Glass building within DreamScape. Each floor is dedicated to a particular activity or sport with picturesque views from every vantage point. DreamScape will be the only facility in Manitoba to sustain maintenance free engineered real turf grass.
          </p>
          <p>
            The Sportsplex features:
          </p>
          <ul className="simple-list">
            <li>Trees and vegetation specifically chosen for their beauty and relaxing qualities</li>
            <li>Jog, run or rollerblade on our parks and trails in a natural setting year-round</li>
            <li>Enjoy a soothing walk in luscious green spaces complimented with ponds and walking bridges, even gondola rides</li>
            <li>Year-round bird sanctuary, lakes and rivers in the first eco system of its kind</li>
            <li>Adding a different level of luxury to residential ownership with our year-round dog park</li>
          </ul>

          <p>
            DS1 building the mega structures of the future with the environment, our climate and society in mind.
          </p>
        </div>
      </div>
    </>
  );
}


