import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/adultzone.jpg";
import { useOutletContext } from "react-router-dom";

export const Adultzone = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="Premium Space and Nightlife Zone" subText="" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Premium Space and Nightlife Zone</h2>
          <p>
            Without peers in the region, the project's 500 acres superstructure site is strategically located and designed to provide a spacious area for a much needed environmentally friendly mega-project amidst the busy city life.
          </p>
          <p>
            Having no exact competition for the markets created and captured by DreamScape, with its mix of facilities and management capabilities, we will ensure success in both short-term and long-term development.
          </p>
          <p>
            With its unique design and lavish array of excitement mixed with business offerings, DreamScape will unequivocally become a frequent destination for tourists. A key feature of DreamScape is providing clean power. Utilizing a geothermal distribution network for power and heating/cooling as well as the collection of rain and snowfall runoff for its non-essential water usage (non-potable), will provide DreamScape the advantage of passing on a lower cost to its retailers and better management of its utilities.
          </p>
        </div>
      </div>
    </>
  );
}


