import React, { useEffect, useState } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import "./style.css";
import hero from "../../assets/hero/hero13.jpg";
import { useOutletContext } from "react-router-dom";
import emailjs from '@emailjs/browser';
export const Contact = () => {
  const [message, setMessage] = useState({
    type: "",
    message: "",
    header: ""
  })

  const [submitted, setSumbitted] = useState(false);

  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/contact");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


  const sumbitForm = (e) => {
    e.preventDefault();
    const frmData = new FormData(e.target);
    const email = Object.fromEntries(frmData.entries()).email;
    const name = Object.fromEntries(frmData.entries()).name;
    const message = Object.fromEntries(frmData.entries()).description;
    setSumbitted(true);
    if (email !== "" && name !== "" && message !== "") {

      var templateParams = {
        name, email, message
      };

      emailjs.send('service_utpu9el', "template_35e3k2j", templateParams, "Xfhd260geWUUKQcyZ").then(function (response) {
        setMessage({
          type: "success",
          message: "Message sent successfully, Our Support team will get back to you shortly.",
          header: "Message Sent!"
        });

        e.target.reset(); // This will reset the form fields
      }, function (error) {
        setMessage({
          type: "error",
          message: "Please contact Dreamscape directly via email. We are facing some issues with the form.",
          header: "Something went wrong!"
        });
      });

    } else {
      setMessage({
        type: "error",
        message: "Please make sure to fill the data before submitting.",
        header: "Missing Field!"
      });
    }
  }
  return (
    <>
      <PageHeader title="CONTACT DREAMSCAPE" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>
            Contact us at:
            <br />
            support@dreamscapewinnipeg.com
            <br />
            or use the contact form below
          </h2>
          <p>
            Please fill out the following form with your questions and/or
            feedback regarding the development of the Dreamscape project. It is
            our goal to respond to you within 24 hours of receipt of your
            submission.
          </p>
          <form className="contact-page-form" onSubmit={sumbitForm}>
            <div className="single-col">
              <label>Full Name *</label>
              <input placeholder="John Smith" type="text" required name="name" />
            </div>
            <div className="single-col">
              <label>Email Address *</label>
              <input placeholder="example@example.com" type="email" name="description" required name="email" />
            </div>
            <div className="single-col">
              <label>Questions and/or comments *</label>
              <textarea required placeholder="Speak your mind"></textarea>
            </div>
            <div className="single-col">
              {submitted && <div><p> {message.header} </p> <p>{message.message}</p></div>}
            </div>
            <button>Send to Dreamscape</button>
          </form>
        </div>
      </div>
    </>
  );
};
