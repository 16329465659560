import img1 from '../../assets/banner/hero/hero1.jpg';
import img2 from '../../assets/banner/hero/hero2.jpg';
import img3 from '../../assets/banner/hero/hero3.jpg';
import img4 from '../../assets/banner/hero/hero4.jpg';
import img5 from '../../assets/banner/hero/hero5.jpg';
import img6 from '../../assets/banner/hero/hero6.jpg';
import img7 from '../../assets/banner/hero/hero7.jpg';
import img8 from '../../assets/banner/hero/hero8.jpg';
import img9 from '../../assets/banner/hero/hero9.jpg';
import img10 from '../../assets/banner/hero/hero10.jpg';
import img11 from '../../assets/banner/hero/hero11.jpg';
import img12 from '../../assets/banner/hero/hero12.jpg';
import img13 from '../../assets/banner/hero/hero13.jpg';
import img14 from '../../assets/banner/hero/hero14.jpg';
import img15 from '../../assets/banner/hero/hero15.jpg';
import img16 from '../../assets/banner/hero/hero16.jpg';
import img17 from '../../assets/banner/hero/hero17.jpg';
import img18 from '../../assets/banner/hero/hero18.jpg';
import img19 from '../../assets/banner/hero/hero19.jpg';
import img20 from '../../assets/banner/hero/hero20.jpg';
import img21 from '../../assets/banner/hero/hero21.jpg';
import img22 from '../../assets/banner/hero/hero22.jpg';
import img23 from '../../assets/banner/hero/hero23.jpg';
import img24 from '../../assets/banner/hero/hero24.jpg';
import img25 from '../../assets/banner/hero/hero25.jpg';
import img26 from '../../assets/banner/hero/hero26.jpg';
import img27 from '../../assets/banner/hero/hero27.jpg';
import img28 from '../../assets/banner/hero/hero28.jpg';
import img29 from '../../assets/banner/hero/hero29.jpg';
import img30 from '../../assets/banner/hero/hero30.jpg';
import img31 from '../../assets/banner/hero/hero31.jpg';


import condo1 from '../../assets/banner/hero/condo1.jpg';
import condo2 from '../../assets/banner/hero/condo2.jpg';
import condo3 from '../../assets/banner/hero/condo3.jpg';
import condo4 from '../../assets/banner/hero/condo4.jpg';
import condo5 from '../../assets/banner/hero/condo5.jpg';
import condo6 from '../../assets/banner/hero/condo6.jpg';

import premium1 from '../../assets/banner/hero/premium1.jpg';
import premium2 from '../../assets/banner/hero/premium2.jpg';
import premium3 from '../../assets/banner/hero/premium3.jpg';
import premium4 from '../../assets/banner/hero/premium4.jpg';
import premium5 from '../../assets/banner/hero/premium5.jpg';
import premium6 from '../../assets/banner/hero/premium6.jpg';
import premium7 from '../../assets/banner/hero/premium7.jpg';
import premium8 from '../../assets/banner/hero/premium8.jpg';
import premium9 from '../../assets/banner/hero/premium9.jpg';
import premium10 from '../../assets/banner/hero/premium10.jpg';

import amusement1 from '../../assets/banner/hero/amusement1.jpg';
import amusement2 from '../../assets/banner/hero/amusement2.jpg';
import amusement3 from '../../assets/banner/hero/amusement3.jpg';
import amusement4 from '../../assets/banner/hero/amusement4.jpg';
import amusement5 from '../../assets/banner/hero/amusement5.jpg';
import amusement6 from '../../assets/banner/hero/amusement6.jpg';
import amusement7 from '../../assets/banner/hero/amusement7.jpg';
import amusement8 from '../../assets/banner/hero/amusement8.jpg';
import amusement9 from '../../assets/banner/hero/amusement9.jpg';
import amusement10 from '../../assets/banner/hero/amusement10.jpg';
import amusement11 from '../../assets/banner/hero/amusement11.jpg';
import amusement12 from '../../assets/banner/hero/amusement12.jpg';
import amusement13 from '../../assets/banner/hero/amusement13.jpg';
import amusement14 from '../../assets/banner/hero/amusement14.jpg';
import amusement15 from '../../assets/banner/hero/amusement15.jpg';
import amusement16 from '../../assets/banner/hero/amusement16.jpg';
import amusement17 from '../../assets/banner/hero/amusement17.jpg';
import amusement18 from '../../assets/banner/hero/amusement18.jpg';

import ampetheater from '../../assets/banner/hero/ampitheatre.jpg';
import ampetheater1 from '../../assets/banner/hero/ampitheatre1.jpg';
import ampetheater2 from '../../assets/banner/hero/ampitheatre2.jpg';
import ampetheater3 from '../../assets/banner/hero/ampitheatre3.jpg';

import hotelnew1 from '../../assets/banner/hero/hotelnew1.jpg';
import hotelnew2 from '../../assets/banner/hero/hotelnew2.jpg';
import hotelnew3 from '../../assets/banner/hero/hotelnew3.jpg';
import hotelnew4 from '../../assets/banner/hero/hotelnew4.jpg';
import hotelnew5 from '../../assets/banner/hero/hotelnew5a.jpg';
import hotelnew6 from '../../assets/banner/hero/hotelnew6.jpg';
import hotelnew7 from '../../assets/banner/hero/hotelnew7.jpg';
import hotelnew8 from '../../assets/banner/hero/hotelnew8.jpg';
import hotelnew9 from '../../assets/banner/hero/hotelnew9.jpg';
import hotelnew10 from '../../assets/banner/hero/hotelnew10.jpg';

export const hotelnewImages = [hotelnew1, hotelnew2, hotelnew3, hotelnew4, hotelnew5, hotelnew6, hotelnew7, hotelnew8, hotelnew9, hotelnew10];
export const amusementImages = [amusement1, amusement2, amusement3, amusement4, amusement5, amusement6, amusement7, amusement8, amusement9, amusement10, amusement11, amusement12, amusement13, amusement14, amusement15, amusement16, amusement17, amusement18];
export const premiumImages = [premium1, premium2, premium3, premium4, premium5, premium6, premium7, premium8, premium9, premium10];
export const ampeImage = [ampetheater, ampetheater1, ampetheater2, ampetheater3];
export const condoImages = [condo1, condo2, condo3, condo4, condo5, condo6];
export const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31];


