import React, { useEffect } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import hero from "../../assets/hero/hero30.jpg";
import { useOutletContext } from "react-router-dom";

export const Community = (prop) => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <PageHeader title="GIVING BACK TO THE COMMUNITY" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <p>
            DreamScape is a mega project. We are committed to helping
            communities thrive and to see Manitoba grow; while helping our local
            and international charities in the process. Bringing everyone
            together, inspiring a city and making a difference in people's
            lives; it's all part of being a good partner. It is a commitment,
            rooted in the belief, that we can use our business model to bring
            about a real positive change in the world and the communities we
            live in.{" "}
          </p>
          <p>
            It is our goal to further promote and inspire philanthropy in our
            province.
          </p>
          <p>Our Approach: All Events Will Be Held at DreamScape</p>
          <ul className="simple-list">
            <li>
              A special event once a month dedicated to school groups and
              non-profit organizations – experience all that DreamScape has to
              offer free of charge.
            </li>
            <li>
              Organize/Sponsor multiple fundraisers including marathons, fun
              runs, golf tournaments etc., for charities and organization
            </li>
            <li>
              Special nights where a portion of the proceeds collected will go
              to local charities and non- profit programs.
            </li>
            <li>Organize food drives, hampers and donations</li>
          </ul>
          <p>
            In addition, DreamScape will happily donate our time and resources
            to help build new facilities and foster programs that satisfy a need
            in the adjacent communities.
          </p>
          <p>
            These are just some of the many ways we will give back to the place
            we love and call home while leaving a lasting impression on those in
            need.
          </p>
        </div>
      </div>
    </>
  );
};
