import React, { useEffect } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import hero from "../../assets/hero/hero03.jpg";
import { useOutletContext } from "react-router-dom";
import image from './image';


import { Slideshow } from "../../components/Slideshow/Slideshow";
export const Features = (prop) => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/feature");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="UNIQUE FEATURES" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Key to Success</h2>
          <p>
            Without peers in the region, the project is strategically located
            and designed to provide a much needed global
            destination/environmental friendly mega-project amidst the busy city
            life.{" "}
          </p>
          <p>
            Having no exact competition for the markets created, DreamScape One
            with its mix of facilities, attractions and management capabilities
            will ensure success in both short-term and long-term development.{" "}
          </p>
          <p>
            DreamScape will unequivocally become a frequent destination for
            tourists, with its unique design and lavish array of amenities mixed
            with business offerings. A key focus of DS1 is the environment and
            renewable energy. Utilizing a geothermal distribution network for
            heating/cooling as well as the collection of rain and snowfall
            runoff (free water) for its non-essential water usage (non-potable)
            will provide DS1 the advantage of passing on a lower cost to its
            retailers and better management of its utilities.
          </p>

          <Slideshow image={image} />
          <p>
            The key to DS1 success starts with our team. Our guest’s
            expectations will be high, our service standards will follow suit.
            To be truly successful our standard practice will be best in class:
          </p>
          <ul className="simple-list">
            <li>
              Dedication to the finest quality products and "make it happen no
              matter what" customer service levels
            </li>
            <li>On-going employee education and recognition programs</li>
            <li>
              Taking full advantage of our assets, viability, strength and
              competitive advantages to ensure success through build-up and
              retaining market niches
            </li>
            <li>
              Continued support of local, regional, provincial and federal
              government programs
            </li>
            <li>
              Giving back to the community by assisting local and national
              charities with donations, events and fundraisers at DreamScape One
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
