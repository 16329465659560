
import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/hero29.jpg";
import { useOutletContext } from "react-router-dom";
import image from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';
export const Drivein = () => {

  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="DRIVE-IN MOVIE THEATRES" subText="DreamScape One, will be home to two unique drive-in movie theatres, featuring new Hollywood hits and classic titles year round." backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Retail</h2>
          <p>
            These two exclusive Drive-In movie theatres would be located within the confines of our climate- controlled environment.
          </p>
          <p>
            Surrounded by our green space tree-line, concession areas and children’s park, movie-goers will enjoy and welcome this modern, nostalgic drive-in experience. With our silver screen recessed into a clamshell, creating the opportunity for all- day screening; locals and visitors alike, will be able to enjoy their favorite movie, day or night. Full digital picture and sound are only a few of the exciting aspects of our drive-in experience. Having a choice of one of the many vintage cars, fully outfitted for sound and comfort, will certainly make going to the drive-in fun again. Adding to the appeal, our fun and friendly roller-blading staff will bring your favorite burger, milkshake, hotdogs and fries right to your vehicle: the ultimate window service.
          </p>
          <p>
            Featuring new Hollywood blockbusters and classic films, the drive-in movie theatres within DreamScape will be a unique way to watch movies. Theme nights and days will include marathons of your classic films or holiday favorites on big screen. On non-theme nights and days, we will be running first-run movies for your enjoyment. DreamScape will offer not one, but two screens, so our movie-goers will have the option of either drive in your own vehicle, or use one of our classic, vintage vehicles to enjoy the show.
          </p>
          <p>
            Stand out features:
          </p>
          <ul className="simple-list">
            <li>Digital 4K HD projectors.</li>
            <li>Dolby Digital Surround sound.</li>
            <li>Concessions and Restaurants.</li>
            <li>Food Trucks.</li>
            <li>Children's Playground.</li>
          </ul>
          <p>DreamScape bringing the drive-in movie theatre back to life.</p>
        </div>
      </div>
    </>
  );
}


