import React, { useEffect } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import background from "../../assets/hero/hero02.jpg";
import { useOutletContext } from "react-router-dom";
export const Mission = (prop) => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/mission");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="THE MISSION" backgroundImage={background} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Mission</h2>
          <p>
            DreamScape is about people and communities engaged in building a
            global destination. Designed with the environment in mind and by
            utilizing the technologies of tomorrow, we will develop a year-round
            climate controlled, renewable energy facility, that will
            revolutionize the hospitality, tourism and entertainment industries.{" "}
          </p>
          <p>
            We will offer products and services in a vibrant, family theme to
            our guests and residents with best in class value, satisfaction and
            entertainment as our primary focus.
          </p>
        </div>
        <div className="content-sizing single-content-box">
          <h2>Vision</h2>
          <p>
            To be exemplary and a leader in its services by creating lasting
            value for our patrons, employees, sponsors, shareholders and other
            stakeholders.{" "}
          </p>
          <p>
            We will maintain our success by monitoring our visions and goals
            while continuing to be industry leaders in advance technology and
            renewable energy.{" "}
          </p>
          <p>
            DreamScape One – building the world of tomorrow by building smarter
            today.
          </p>
        </div>
      </div>
    </>
  );
};
