import React, { useEffect } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import "./style.css";
import ig from "../../assets/sponsors/ig.jpg";
import clearly from "../../assets/sponsors/clearinsight.jpg";
import fu from "../../assets/sponsors/4U.png";
import commerce from "../../assets/sponsors/commerce.jpg";
import overarch from "../../assets/sponsors/overarch.png";
import me3 from "../../assets/sponsors/ME3.jpg";
import laughter from "../../assets/sponsors/laughter.jpg";
import teammade from "../../assets/sponsors/teammade.png";
import dcd from "../../assets/sponsors/dcd.svg";
import carrot from "../../assets/sponsors/carrot.jpg";
import gwt from "../../assets/sponsors/gwt.jpg";
import superb from "../../assets/sponsors/superb.svg";
import royal from "../../assets/sponsors/royal.png";
import walleyewings from "../../assets/sponsors/walleyewings.png";
import summit from "../../assets/sponsors/summit.png";
import hero from "../../assets/hero/hero05.jpg";
import { useOutletContext } from "react-router-dom";

export const Sponsors = (prop) => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/sponsors");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="BE A PART OF DREAMSCAPE" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <p>
            DreamScape (DS1) is an entertainment, hospitality and retail mega
            project. It is an enclosed climate controlled environment fostering
            a business model that is unhampered by weather conditions. This is a
            one of a kind venture, providing amazing potential for brand
            awareness and cross-over promotions. Although our core objective is
            environmental/climate change issues our theming remains a Global
            Destination Theme. Creating incredible opportunity for Sponsors and
            Businesses alike, we highly recommend getting involved on the ground
            floor. Join some of the great companies that are already on board.
            Whether you are interested in Naming Rights, Sponsorship,
            Advertising, Public Relations or just want to help us design our
            DreamScape characters, we would love to hear from you.{" "}
          </p>
          <p>
            Please Contact Us by clicking the link to our contact page. We look
            forward to hearing from you once you have taken the opportunity to
            peruse our website.
          </p>
          <h2>Some of Our Current Sponsors</h2>

          <ul className="testimonal-page-list">
            <li>
              <img src={ig} alt="" loading="lazy" />
              <div>
                <p>
                  “Dreamscape is a much needed solution for the Manitoba region
                  and Canada overall. Investors Group is pleased to support any
                  opportunities that will bring new revenues streams, global
                  traffic and ongoing exposure on the world stage for Manitoba.
                </p>
                <p>
                  We are proud to be part of something so amazing and historical
                  in the making, something that brings a long term positive
                  economic impact to our province. With our roots firmly planted
                  in Manitoba for 70 years and growing nationwide over the
                  years, we understand large scale ventures; especially when
                  they bring long term growth and solutions to support local
                  businesses and the families that lives here. The DreamScape
                  Project embodies the key criteria’s we admire – privately
                  funded, job creation, long term growth and the environment.
                  The DS1 project exemplifies us as Canadians and our role in
                  the international community on climate change. We are proud to
                  stand behind such a bold Canadian venture in pioneering new
                  frontiers for the future. DreamScape bring robust growth for
                  our local businesses, communities and city, while making
                  Manitoba to be a true global destination the world can
                  admire."
                </p>
                <div>
                  <p>Janeen Robinson and Associates</p>
                  <p>Investors Group</p>
                </div>
              </div>
            </li>
            <li>
              <img src={clearly} alt="" loading="lazy" />
              <div>
                <p>
                  "We need vision to move forward to expand our concepts and
                  then we need drive to move those visions ahead. This
                  development will benefit Manitobans during construction and
                  after completion. Looking around Manitoba we have proven that
                  we are capable of tackling major projects. We have a new
                  stadium, new arena, a beautiful bridge and Forks development,
                  the expanded floodway, and the massive hydro development in
                  the North as well as dozens of others. When we look back
                  Manitobans built a brand new city in a remote wilderness area
                  (Thompson), several smaller communities in the North, and
                  completed both the floodway and 5 generating stations on the
                  Nelson River.
                </p>
                <p>
                  We as Manitobans have the capacity to make this dream, this
                  vision become our legacy."
                </p>
                <div>
                  <p>Gary Williams</p>
                  <p>Clearly InSight IP Network & Video Ltd</p>
                </div>
              </div>
            </li>
            <li>
              <img src={fu} alt="" loading="lazy" />
              <div>
                <p>
                  "The Dreamscape Winnipeg project is unlike any other being
                  developed globally. It offers Manitoba the opportunity to
                  truly become a dominant player on the world stage. The
                  economic impact this project can deliver is sustainable. From
                  the construction phase right through to the completion phase
                  and for many years thereafter. While our climate here in
                  Manitoba has been a limiting factor to some extent for our
                  growth, our stable and available land mass make Manitoba the
                  perfect location for this project. Our renewable power
                  resources and agricultural land provide what is necessary to
                  sustain a project of such magnitude. Becoming a world class
                  destination for tourists can only benefit our economy in our
                  province and country for many years, ensuring jobs, prosperity
                  and a better quality of life for all those who reside here,
                  and all Canadians."
                </p>
                <div>
                  <p>Eugene Uhryniuk</p>
                  <p>President and CEO</p>
                </div>
              </div>
            </li>
            <li>
              <img src={commerce} alt="" loading="lazy" />
              <div>
                <p>
                  "Manitoba needs a project like Dreamscape Winnipeg. It's time
                  we catch up with the rest of the world and climb on the
                  technology train to the future. Dreamscape will be a tourism
                  draw, create thousands of jobs, and boost the local economy.
                  Seasonal businesses will be able to operate year round and one
                  of the major hurdles, the cold weather, will have less of an
                  effect on shopping habits.
                </p>
                <p>I support the Dreamscape project 100%."</p>
                <div>
                  <p>Troy Goldenthal</p>
                  <p>President and Founder, The Commerce Exchange</p>
                </div>
              </div>
            </li>

            <li>
              <img src={overarch} alt="" loading="lazy" />
              <div>
                <p>
                  "It is with great enthusiasm an excitement that I write this
                  email in support of the DreamScape One Project with high hopes
                  that Winnipeg, Manitoba region will be home to this amazing
                  project.
                </p>
                <p>
                  After reviewing the website and the extensive planning and
                  development, I can see that this well thought out and
                  exclusive world class destination will be a welcomed revenue
                  generation and economic development boon for the city of
                  Winnipeg and the Province of Manitoba.
                </p>
                <p>
                  Seeing the potential of a controlled environment venue such as
                  this will certainly provide both Canadian and Global tourism
                  with exclusive services which will eloquently feature Winnipeg
                  in a positive light on the world stage.
                </p>
                <p>
                  As a company devoted to climate change, renewable energy,
                  energy reduction in communities and the IC&I; sector of the
                  economy, I absolutely applaud the efforts to make this a truly
                  sustainable venue.
                </p>
                <p>
                  I am enthusiastically looking forward to seeing this project
                  develop in Winnipeg, Manitoba very soon."
                </p>

                <div>
                  <p>Darrel Olson</p>
                  <p>Vice President, Operations And Project Development</p>
                </div>
              </div>
            </li>

            <li>
              <img src={me3} alt="" loading="lazy" />
              <div>
                <p>
                  "I first heard about Dreamscape from a business acquaintance
                  of mine as he was sharing this dream and vision with me. He
                  then arranged a meeting with myself and the founder and CEO of
                  Dreamscape Mr. Anthony Panchoo. From the moment he began
                  sharing his vision about his plans for the future it really
                  resinated with me since I’ve lived in Winnipeg all my life and
                  I’ve felt for a very long time that this is what
                  Winnipeg/Manitoba has been needing. Myself and ME3 Global
                  Solutions is 100% behind Anthony and his vision for what
                  Dreamscape can and will do for the city and the Province.
                </p>
                <p>
                  When we include the Zoo, The Forks, The Museum for Human
                  Rights and Dreamscape this will be a destination of choice for
                  tourists around the globe and I look forward to it being
                  launched soon."
                </p>
                <div>
                  <p>Robert Lauze</p>
                  <p>ME3 Global Solutions</p>
                </div>
              </div>
            </li>

            <li>
              <img src={laughter} alt="" loading="lazy" />
              <div>
                <p>
                  {" "}
                  "Growing up in Winnipeg, we love our outdoor winter
                  festivities but we have always felt we needed a year-round
                  playground to fill the long winter months. When we learned
                  about Dreamscape and met Anthony Panchoo, we became very
                  excited about the opportunity and growth for our home city and
                  province. We at Laughter Without Borders – Entertainment
                  Agency, fully support Manitoba finally getting the opportunity
                  to be home of a world-renowned tourist destination. An indoor
                  amusement park full of entertainment and fun for the whole
                  family is exactly what this province needs! Whether we are at
                  plus 40 or minus 40 it is time for the world to experience
                  Manitoba to the extremes. We are a warm-hearted city and it is
                  time we can show it off all year long. Extreme temperatures
                  will not stop the extreme fun and we are extremely proud to
                  see this project come to life. Dreamscape proves that Manitoba
                  knows how to Dream Big, Laugh Lots, and be Amazing!!!"
                </p>

                <div>
                  <p>Deanna Hartmier</p>
                  <p>Val Meiners</p>
                  <p>Laughter without Borders</p>
                </div>
              </div>
            </li>

            <li>
              <img src={teammade} alt="" loading="lazy" />
              <div>
                <p>
                  "Anthony Panchoo is one of the world’s leading visionaries,
                  and his project Dreamscape One, is one of the most innovative
                  and groundbreaking concepts our generation may ever have the
                  honour of experiencing. His ability to macro-manage is
                  unbelievable, all while staying down to earth and contributing
                  to the Province of Manitoba. Dreamscape One is a phenomenal
                  project that will bump humanity up to the next bracket and the
                  intellectual and structural savvy behind this initiative has
                  the capacity to change the world amidst so many epidemics. Mr.
                  Panchoo, is an intergenerational leader and will go down as a
                  history maker!"
                </p>
                <div>
                  <p>Valen Vergara</p>
                  <p>President of Team Made Real Estate Inc.</p>
                </div>
              </div>
            </li>

            <li>
              <img src={dcd} alt="" loading="lazy" />
              <div>
                <p>
                  "Construction of the Dreamscape One project is a surefire way
                  for Manitoba to be on the map as a tourism destination.
                  Growing up with our long winters, it seems that there are very
                  few options to experience indoor amusement activities.
                  Anthony's vision of creating an all exclusive, year-round
                  destination ensures that our province would attract tourists
                  from all around the world, while generating major revenue for
                  our province and employment opportunities for thousands.
                </p>
                <p>
                  I stand by Anthony and fully support this project. The
                  prospect of having a project of this magnitude in our own
                  backyard is exciting and deserves the attention and support of
                  all Manitobans. Let's build something extraordinary!"
                </p>
                <div>
                  <p>James Rogowy</p>
                  <p>Owner, Digital Canvas Design</p>
                </div>
              </div>
            </li>

            <li>
              <img src={carrot} alt="" loading="lazy" />
              <div>
                <p>
                  "I was once told to think Bigger than Big. I believe
                  Dreamscape One is a project that is definitely Bigger than
                  Big. Anthony Panchoo is more than a visionary with an idea but
                  a leader who has the will to get it done.
                </p>
                <p>
                  Canada and particular Manitoba, has an opportunity to
                  capitalize on a project that can create a stronger economy and
                  create jobs of a very large magnitude. Bringing workers,
                  scientists, and other professionals from around the world, in
                  addition to millions of tourists, puts Manitoba in its own
                  category on the world stage. A project like this can
                  positively change and transform the economy in Manitoba
                  forever and put us on the map. If West Edmonton Mall can bring
                  in over 30 million visitors a year, imagine the potential of
                  Dreamscape One. We will support this venture to our fullest
                  capacity."
                </p>
                <div>
                  <p>The Fresh Carrot</p>
                </div>
              </div>
            </li>

            <li>
              <img src={gwt} alt="" loading="lazy" />
              <div>
                <p>
                  "The DreamScape1 concept is by far the biggest and most
                  exciting project Manitoba has ever had the privilege to
                  accommodate. This is an unbelievable opportunity for this
                  province to potentially have an internationally recognized
                  jewel, amongst some of the existing wonders of the world, such
                  as:
                </p>
                <p>
                  The Hoover Dam, The Pyramids of Giza, The Taj Mahal and The
                  Panama Canal.
                </p>
                <p>
                  These construction projects have commanded the admiration and
                  respect of millions around the world and have always showed
                  positive economic impact to their communities. Similarly here,
                  because of Mr. Panchoo’s vision and many sacrifices, Manitoba
                  can further prosper by attracting new visitors, world-class
                  exposure, thousands of jobs and show the world how Canada is
                  helping towards a brighter and more promising future of our
                  planet.
                </p>
                <p>
                  Manitoba is the perfect location and environment to pilot what
                  could be the future of climate controlled renewable energy
                  facilities to its zenith. This project will expand and further
                  our adaptability as humans. I am very pleased to have had the
                  opportunity to speak to Mr. Panchoo first hand about this
                  project. I personally feel we are extremely fortunate to have
                  such a visionary in our province and would gladly assist in
                  any capacity to help bring this amazing opportunity to life
                  for Manitoba."
                </p>
                <div>
                  <p>Patrick Dorge</p>
                  <p>Mortgage Specialist/Castle Mortgage</p>
                  <p>GWT Corp</p>
                  <p>FERI Designer Lines</p>
                </div>
              </div>
            </li>

            <li>
              <img src={superb} alt="" loading="lazy" />
              <div>
                <p>
                  Superb Construction Group Ltd is uniquely positioned as
                  industry leading underground utility experts, specialized in
                  bringing you the latest technology and global advancements in
                  fiber optics, street and pathway lighting and all other
                  utilities. The family business is celebrating its 20th
                  anniversary in 2017 since starting the underground utility
                  division and we are looking forward to continuing to deliver
                  our Professional Service with SUPERB RESULTS. We would like to
                  thank all of our much valued customers, employees and
                  suppliers for their loyalty and are excited to continue to
                  build new relationships into the future!
                </p>
              </div>
            </li>

            <li>
              <img src={royal} alt="" loading="lazy" />
              <div>
                <p>
                  "Brad Hirst is an Award winning Realtor® with Royal LePage
                  Prime Estate. Brad has been an active community member in the
                  City of Selkirk for over 25 years, from receiving his diploma
                  from the Lord Selkirk Regional Comprehensive Secondary School
                  to working in the Lord Selkirk School Division for over 14
                  years. This activity has allowed him to get involved in a
                  variety of different organizations. Brad is involved in the
                  Icelandic cultural community, rugby community and the Masonic
                  community in Manitoba. In 2014 Brad joined the Royal LePage
                  Prime Real Estate family and started his journey in Real
                  Estate. Since then he has helped many families throughout the
                  Interlake and Winnipeg areas, earning him the Sales
                  Achievement and Master Sales Award from Royal LePage Canada.
                  With his personable attitude and expertise in the real estate
                  market and industry, this makes Brad Hirst and Royal LePage
                  Prime Real Estate the right choice in all your real estate
                  needs."
                </p>
              </div>
            </li>

            <li>
              <img src={walleyewings} alt="" loading="lazy" />
              <div>
                <p>
                  Walleye Wings is an innovative freshwater fish processing
                  company. The company produces unique flash-frozen, entrees and
                  appetizers that leverages the growing trend for freshwater
                  cuisine. Its product line consists of five distinct items:
                  Walleye Wings, Walleye Wings Breading, Walleye Popcorn Cheeks,
                  as well as Whitefish & Northern Pike Cakes. These products
                  utilize traditionally discarded parts of the fish, including
                  the meaty morsel attached to the fin.
                </p>
              </div>
            </li>

            <li>
              <img src={summit} alt="" loading="lazy" />
              <div>
                <p>
                  Summit View Builders Inc. provides world class experiences as
                  your home transformational leader. Our services include full
                  renovations and home additions in and around Winnipeg,
                  Manitoba, Canada.
                </p>
                <p>
                  We would enjoy the opportunity to help you with a free
                  consultation or estimate, take the first step and contact a
                  Summit View professional today!
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
