import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { LandingPage } from "./pages/LandingPage";
import { Project } from "./pages/project/Project";
import { Community } from "./pages/community/Community";
import { Mission } from "./pages/mission/Mission";
import { Engineering } from "./pages/engineering/Engineering";
import { Visionary } from "./pages/visionary/Visionary";
import { Location } from "./pages/location/Location";
import { Contact } from "./pages/contact/Contact";
import { Features } from "./pages/features/Features";
import { Faq } from "./pages/faq/Faq";
import { Sponsors } from "./pages/sponsors/Sponsors";
import { BackToTop } from "./components/back-to-top-button/BackToTop";
import { Condo } from "./pages/features-pages/Condo";
import { Hotels } from "./pages/features-pages/Hotels";
import { Mall } from "./pages/features-pages/Mall";
import { Amusement } from "./pages/features-pages/Amusement";
import { Waterpark } from "./pages/features-pages/Waterpark";
import { Winterpark } from "./pages/features-pages/Winterpark";
import { Golf } from "./pages/features-pages/Golf";
import { Theater } from "./pages/features-pages/Theater";
import { Drivein } from "./pages/features-pages/Drivein";
import { Adultzone } from "./pages/features-pages/Adultzone";
import { Skatingrink } from "./pages/features-pages/Skatingrink";
import { Green } from "./pages/features-pages/Green";
import { Environment } from "./pages/features-pages/Environment";
import Error404 from "./pages/Error404/Error404";

const App = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  const pageScroll = () => {
    const scrollTop = window.scrollY || window.pageYOffset;
    setBackToTopButton(scrollTop > 300 ? true : false);
  };

  useEffect(() => {
    window.addEventListener("scroll", pageScroll);
    return () => window.removeEventListener("scroll", pageScroll);
  }, []);

  return (
    <>
      {/* Make the nav bar active when its on a specfic page*/}
      <Routes>
        <Route path="/" element={<LandingPage />}>
          <Route index element={<Project />} />
          <Route path="mission" element={<Mission />} />
          <Route path="features" element={<Features />} />
          <Route path="community" element={<Community />} />
          <Route path="sponsors" element={<Sponsors />} />
          <Route path="faq" element={<Faq />} />

          <Route path="hotels" element={<Hotels />} />
          <Route path="condo" element={<Condo />} />
          <Route path="mall" element={<Mall />} />
          <Route path="amusement" element={<Amusement />} />
          <Route path="waterpark" element={<Waterpark />} />
          <Route path="winterpark" element={<Winterpark />} />
          <Route path="theater" element={<Theater />} />
          <Route path="drivein" element={<Drivein />} />
          <Route path="golf" element={<Golf />} />
          <Route path="adultzone" element={<Adultzone />} />
          <Route path="skatingrink" element={<Skatingrink />} />
          <Route path="green" element={<Green />} />
          <Route path="environment" element={<Environment />} />

          <Route path="engineering" element={<Engineering />} />
          <Route path="visionary" element={<Visionary />} />
          <Route path="location" element={<Location />} />
          <Route path="contact" element={<Contact />} />
        </Route>

        <Route path="*" element={<Error404 />} />
      </Routes>
      <BackToTop backToTopButton={backToTopButton} />
    </>
  );
};

export default App;
