import React from 'react'
import "./style.css";
import {Reveal} from "../../components/Reveal/Reveal";

export const PageHeader = (prop) => {
  return (
    <section className='page-header-container' style={{backgroundImage:`url(${prop.backgroundImage})`}}>
     
        <div>
          <Reveal>
          <h1>{prop.title}</h1>
          </Reveal>
        </div>
      
    </section>
  )
}
