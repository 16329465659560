import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/hero/hero08.jpg";
import { useOutletContext } from "react-router-dom";
export const Waterpark = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="WATER PARK" subText="DreamScape One Water Park will be open year round offering wild water themed excitement under one climate controlled roof." backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Retail</h2>
          <p>
            Since the late nineties, water parks have become somewhat of a phenomenon to most areas; including Manitoba. It is hard to say what the true draw and attraction is but it could be the wave pools, riding a raft on the raging river or just coming down a hundred foot slide.
          </p>
          <p>
            DreamScape was designed with such a water park in mind. A destination size water park with pools and games for the kids; restaurants and wet bars for the adults and lots of slides, rides and attractions, making this a destination for the whole family. At DreamScape, we took the excitement one step further and went year-round in a temperature controlled environment, so you never have to worry about the inclement weather outside in our seasonal climate.
          </p>
          <p>
            Designed to thrill visitors and locals alike, DreamScape’s water park will be worth the wait – visually stunning, vibrant, fun-filled and exciting for everyone. With its unique design and wide range of lavish rides, our facilities will be a combination of engineering brilliance and environmental reliability.
          </p>
          <p>
            An environmental friendly design with slides, lazy rivers and wave pools, sustainable solutions for heating and cooling year round. An all year waterpark - tropical theme, boardwalks, white sand beach areas
          </p>
          <p>
            Passes available for all occasions: half day, full day, multi-day, monthly, seasonal or annual
          </p>
          <p>
            At DreamScape we are delighted to offer a family theme, entertainment and activities designed for your pleasure where memories will be made.
          </p>
        </div>
      </div>
    </>
  );
}


