
import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/hero13.jpg";
import { useOutletContext } from "react-router-dom";
import image from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';
export const Golf = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="GOLF COURSE" subText="" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Golf Course</h2>
          <p>
            Imagine the feeling of golfing on one of our two premier golf courses as you watch the snow falling outside. Natural grass, beautiful club houses, caddies, driving-range, pro-shop and trainers are the basic staples of golfing. At DreamScape you will find all this and more 365 days a year, under our superstructure roof regardless of the weather outside. Too many mosquitoes, windy, cold days, or just plain old winter will be a thing of the past.
          </p>
          <p>
            Perfectly designed by professional golfers, the 18 hole championship golf course is for the avid and serious golfer. Wide fairways, dog legs, sand-traps and water hazards are just a few of the challenges you will face while playing this course. For the more novice golfer, we have designed a recreational 9 hole golf course built for players just getting their game off the ground or looking for a fun, exciting round of golf. We are designing our golf courses with you in mind, professionally sanctioned so it can have everything you love about golf.
          </p>
          <p>
            The two golf courses will feature:
          </p>
          <ul className="simple-list">
            <li>A Par 73 - 18 hole championship golf course. Design for avid golfers, this course will be challenging and compelling featuring massive bunkers, water hazards and expansive fairways</li>
            <li>A Par 36 - 9 hole recreational golf course for novice to intermediate golfers for a quick fun round of golf featuring open landscapes, tree lines and strategically located hazards</li>
            <li>State of the art golf carts and other new technologies allowing for an heightened experience</li>
            <li>Daily green fees, seasonal and yearly golf memberships</li>
            <li>First class clubhouse and pro shop facilities</li>
            <li>Learn to golf or train with one of our seasoned golf pros</li>
            <li>Multiple driving ranges and chipping/putting centers</li>
            <li>Ample parking available right near the club house</li>
          </ul>
          <p>DreamScape’s uniquely designed golf courses will challenge any golfer from novice to professional in the world’s first enclosed environment, professional golfing, you will love it.</p>
        </div>
      </div>
    </>
  );
}


