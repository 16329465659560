import React from "react";
import "./style.css";
import svg from "../../assets/404.svg";

const Error404 = () => {
  return (
    <>
      <div className="error-container">
        <div className=" content-sizing">
          <img src={svg} alt="svg" />
          <div className="button">
            <button>
              <i class="bi bi-house"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
