import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import { useOutletContext } from "react-router-dom";
import hero from "../../assets/banner/hero/ampitheatre.jpg";
import { ampeImage as image } from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';

export const Theater = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="AMPITHEATRE" subText="DreamScape One Amphitheatre will be home to some of the biggest concerts, symphonies, plays, comedy shows and events to come to town." backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Amphitheatre</h2>

          <p>
            DreamScape One will be the home for the largest indoor Amphitheatre in Canada and the largest indoor concert venue in Manitoba. Within its 27,000 plus bowl style seating capacity, this Amphitheatre will showcase an incredible host of talent. Events, concerts, live plays and performances, symphonies, comedy shows, specific types of live sporting events are just some of the many types of shows that we will be featuring. Seating arrangements will be modular allowing us the freedom to set up different styles of events all under the same roof. Our integrated, global marketing program will offer our guests some of the best choices of performers and acts from around the world, on a first-class stage.
          </p>
          <AutoSlideshow image={image} />
          <p>
            The Arts and Culture and Entertainment industry is critically important to us at DreamScape. Creating a venue to showcase professional talent, artists and performers in an atmosphere such as DreamScape was a natural fit indeed.
          </p>
          <p>
            Features and Benefits:
          </p>
          <ul className="simple-list">
            <li>The design is unique and modular to allow for numerous different events</li>
            <li>The structure will be built in a “bowl” style to allow for the best sight lines</li>
            <li>New and exciting concessions, brand name restaurants and much more</li>
            <li>Priority seating for season pass holders</li>
            <li>Points system for frequent attendees with discounts on food and beverage purchases and merchandise.</li>
            <li>Easy access early booking ticket system available either online or at the box office</li>
            <li>Ample ushers and concierge staff available to assist you with seating and information</li>
            <li>Dedicated ample parking designed with ease of access</li>
            <li>Designated seating for our international guests</li>
          </ul>
          <p>
            The Amphitheatre within DreamScape will be a one of a kind venue, giving artists and all performers a spectacular home for arts, culture and entertainment.
          </p>
        </div>
      </div>
    </>
  );
}


