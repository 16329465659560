import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/hero14.jpg";
import { useOutletContext } from "react-router-dom";
import { premiumImages as image } from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';

export const Mall = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="THE RETAIL HUB" subText="The Retail Hub within DreamScape One will meet and exceed your professional or personal needs in every category from shopping to dining to business meetings" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Retail</h2>
          <AutoSlideshow image={image} />
          <p>
            DreamScape will house a Retail Hub which includes a 600 plus stores shopping center with commercial office space, movie theatres, full-service restaurants and food courts. This Retail Hub could become the largest mixed-offering platform in Manitoba under one roof. It is a perfect setting for shopping, dining, hosting business meetings, theatres and much more. DreamScape plans to offer the best in class for all your shopping and business needs.
          </p>
          <p>
            Designed to entice and intrigue our guests to explore all we have to offer, our world-class interior designers and engineers are focused on building a unique international theme for your one of a kind shopping experience.
          </p>
          <p>
            Building the perfect client list for our guests, from grocery stores to flower shops, clothing, footwear or electronics, is an essential part to our commitment of excellence, hospitality and customer satisfaction. The office floors are designed to accommodate business professionals of every caliber and need. Banks, lawyers, accountants, and real estate professionals are among a few examples of the businesses that will be available daily within the DreamScape Retail Hub, to ease your hectic schedule.
          </p>
          <p>
            Providing mobile lockers, guest information service booths and onsite mobile personnel to assist guests are all critical aspects of keeping our guests happy and hands-free to enjoy the full experience at DreamScape.
          </p>
        </div>
      </div>
    </>
  );
}


