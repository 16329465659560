import React, { useEffect } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import hero from "../../assets/hero/hero26.jpg";
import { useOutletContext } from "react-router-dom";
export const Engineering = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/engineering");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="ENGINEERING" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <p>
            Strategically located, DreamScape is primed to become Manitoba’s
            premier attraction!{" "}
          </p>
          <p>
            Within this LEED-Certified state-of-the-art development project,
            approximately 500 acres will be enclosed as a climate controlled
            environment under one continuous superstructure roof, making this an
            exemplary engineering marvel on its own. Not only will this world
            class facility provide multifaceted recreational services, it will
            also operate under a mandate to limit its potential environmental
            footprint. To achieve this, we are adapting creative sustainable
            solutions for heating, cooling, water management, power and resource
            recycling.{" "}
          </p>
          <p>
            Some of the best minds in the field of engineering are diligently
            working, at the challenging dynamics of this project.{" "}
          </p>
          <p>
            At its completion, DreamScape will surpass expectations to become
            the world's best tourism and entertainment centers.{" "}
          </p>
          {/* <h2>Embed Youtube</h2> */}
        </div>
      </div>
    </>
  );
};
