import React, { useEffect } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import hero from "../../assets/hero/hero27.jpg";
import { useOutletContext } from "react-router-dom";

import image from './image';



import { Slideshow } from "../../components/Slideshow/Slideshow";
// import required modules


export const Visionary = () => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/visionary");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="WHAT IT TAKES" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <p>
            DreamScape is a next generation facility designed with the
            environment and our climate concerns in mind. It is going to be the
            largest one roof structure in the world. Fully enclosed and
            temperature controlled, this facility is completely self-sufficient.
            It engages clean renewable energy sources dealing with everything
            internally – hydro, heating/cooling, water and sewer.{" "}
          </p>
          <p>
            Our intention is to build the world of tomorrow by building smarter
            today.
          </p>
          <p>
            The beautiful thing about being a Theme Park is that it is geared to
            an international market, making this a Global Destination spot for
            Manitoba. This will put Manitoba and Canada on the world stage;
            having such a marvel of engineering to show the world within our
            normal cold climate.{" "}
          </p>
          <p>
            One of the many great opportunities of this project for the future
            is the potential to locate others anywhere in the world. Being able
            to live in an environment that is guarded from any weather
            conditions while being self-sufficient is something that does not
            already exist on such a large scale. DreamScape is designed with
            people in mind and can accommodate a large amount of resident
            population in addition to farming and producing fresh fruits and
            vegetables.{" "}
          </p>
          <p>
            This is a Canadian venture working with international assistance at
            all levels, in true Canadian spirit, offering the world a brighter
            future.
          </p>
          <Slideshow image={image} />
          <p>
            Visualizations and information shown on this website are for
            expression and illustrations only; may be subject to change.
          </p>
        </div>
      </div>
    </>
  );
};
