import img1 from '../../assets/banner/hero/smallhero01.jpg';
import img2 from '../../assets/banner/hero/smallhero02.jpg';
import img3 from '../../assets/banner/hero/smallhero03.jpg';
import img4 from '../../assets/banner/hero/smallhero04.jpg';
import img5 from '../../assets/banner/hero/smallhero05.jpg';
import img6 from '../../assets/banner/hero/smallhero06.jpg';
import img7 from '../../assets/banner/hero/smallhero07.jpg';
import img8 from '../../assets/banner/hero/smallhero08.jpg';
import img9 from '../../assets/banner/hero/smallhero09.jpg';
import img10 from '../../assets/banner/hero/smallhero10.jpg';
import img11 from '../../assets/banner/hero/smallhero11.jpg';
import img12 from '../../assets/banner/hero/smallhero12.jpg';

export default [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12];