import React, { useEffect } from "react";
import { PageHeader } from "../../components/page-header/PageHeader";
import { Link, useOutletContext } from "react-router-dom";
import hero from "../../assets/hero/hero08.jpg";
import "./style.css";
export const Faq = (prop) => {
  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/faq");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader
        title="QUESTIONS REGARDING THE PROJECT?"
        backgroundImage={hero}
      />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Frequently Asked Questions</h2>
          <ul className="faq-list">
            <li>
              <h3>Q. Is DreamScape a real place?</h3>
              <p>DreamScape is in the making and soon to become REAL.</p>
            </li>
            <li>
              <h3>Q. When is DreamScape expecting to start construction?</h3>
              <p>
                Our goal is to break ground as soon as possible once all
                necessary processes have been carefully finalized and approved.
                This could be as early as summer of 2017 or further into the
                future, we will keep you updated.
              </p>
            </li>
            <li>
              <h3>
                Q. DreamScape is completely covered and unharmed by the weather
                outside, is it surrounded by a dome? How will this work?
              </h3>
              <p>
                DreamScape One will be an engineering marvel unlike anything you
                have seen before. It will be completely covered by an enclosure
                that will benefit not only as a way to make the facility run 365
                days a year but it will also benefit the environment. The roof
                design will drive all free water (rain and snow) from our heated
                roof to a catchment area. This free water will be filtered and
                used for all non-potable needs such as irrigation of our green
                spaces and landscaped areas. This will truly be a green
                initiative to be proud of.
              </p>
            </li>
            <li>
              <h3>Q. When will DreamScape open?</h3>
              <p>
                DreamScape will be built in three phases. We currently do not
                have a tentative date set for Phase One to become active and
                open to the public but we will keep everyone updated as soon as
                the project time table is finalized.
              </p>
            </li>
            <li>
              <h3>Q. What amenities will be included within DreamScape One?</h3>
              <p>
                A Water Park, Amusement Park, Championship 18 hole Par 73 golf
                course, Recreational 9 hole Par 36 golf course, 2 Luxury
                Boutique Hotels featuring 400 guest rooms per property, Massive
                Convention Centre, Amphitheatre large enough to seat over 20,000
                guests, 2 Drive Inn Movie Theatres, Shopping Centre Retail Hub
                with over 600 retail stores, City Park with running / biking
                trails, Sportsplex featuring baseball, soccer, basketball and
                skating, expansive Fitness Facility, an International Food
                Market, Condominiums all under one superstructure roof with
                retractable sections when weather is right. As well, there will
                be a Winter Park featuring skiing and other winter activities
                outside of the DreamScape mega facility but still on the same
                campus.
              </p>
            </li>
            <li>
              <h3>Q. Will Phase One have the Water Park?</h3>
              <p>
                Yes Phase One has The Water Park and first stage of: The Hotels,
                The Retail Hub, The Golf Course, The Amusement Park, The
                Convention Center and Premium Space Zone, Green Space, Winter
                Park and Condominiums
              </p>
            </li>
            <li>
              <h3>Q. What kind of rides are in the Water Park?</h3>
              <p>
                The Water Park is comparable to some of the biggest Water Parks
                across North America and will therefore have all the major
                expected rides, including a dual wave pool, the lazy river and
                the flow rider as well as various rides to accommodate all ages.
              </p>
            </li>
            <li>
              <h3>Q. How big is the Amusement Park?</h3>
              <p>
                It is a fairly large area comprising of Thrill Rides, Mega
                Coasters and Big Wheel amusement rides, Vertical Balloon rides,
                4D Flying Theater and attractions for all ages.
              </p>
            </li>
            <li>
              <h3>
                Q. What will it cost me to take my family of four to DreamScape
                for the day?
              </h3>
              <p>
                That's a good question but at this time we do not have that
                answer finalized. DreamScape does plan on offering discounted
                rates for local residents.
              </p>
            </li>
            <li>
              <h3>Q. Will I have to pay for parking?</h3>
              <p>
                Parking will be offered in different categories - short term,
                long term, economy etc. but not every category will be
                chargeable. In addition there will be a lot of combination
                packages that includes parking.
              </p>
            </li>
            <li>
              <h3>
                Q. Is the complete facility under the roof, including the Golf
                Course?
              </h3>
              <p>
                Yes, everything including the Golf Course and The City Size
                Parks. DreamScape is an enclosed climate controlled facility.
              </p>
            </li>
            <li>
              <h3>
                Q. Are there real trees, grass and waterways on the Golf Course
                and the Parks?
              </h3>
              <p>Yes, year round.</p>
            </li>
            <li>
              <h3>Q. So I can golf any time of the year?</h3>
              <p>Yes, anytime including December and January.</p>
            </li>
            <li>
              <h3>Q. Is it an 18 hole Golf Course?</h3>
              <p>
                The Golf Course is an 18 hole Par 73 professionally designed
                course as well we will have a 9 hole Par 36 recreational golf
                course.
              </p>
            </li>
            <li>
              <h3>Q. Where will DreamScape be located?</h3>
              <p>
                It will be located in a strategic location with the location to
                be released at a later time; at our public release
              </p>
            </li>
            <li>
              <h3>Q. When will the public release take place?</h3>
              <p>
                We presently have it scheduled for a tentative date for the late
                summer of 2017, but this could change.
              </p>
            </li>
            <li>
              <h3>Q. How big will the hotels be?</h3>
              <p>
                There will be 2 hotels with approximately 1000 rooms per hotel.
              </p>
            </li>
            <li>
              <h3>
                Q. Will there be a local rate offered to Manitobans at the
                hotel?
              </h3>
              <p>
                At this time that answer is unknown but it is preferred to go
                that route.
              </p>
            </li>
            <li>
              <h3>
                Q. Will there be yearly and monthly passes available on top of a
                daily pass to enter DreamScape?
              </h3>
              <p>
                DreamScape will offer many pass options for the facility: half
                day, full day, multi-day, weekly, monthly, seasonal and yearly
                passes will be available for purchase. Discounts will be
                provided based on how many days are being purchased. These daily
                passes will provide you entry into either the Water Park,
                Amusement Park or both parks depending on the ticket purchased.
                More details will be available in the near future.
              </p>
            </li>
            <li>
              <h3>
                Q. Will all facilities require a fee for entry such as shopping
                at the Retail Hub? How will this be broken up?
              </h3>
              <p>
                The shopping centre will not require an entry fee to shop. The
                Golf Course will have green fees and the Amphitheatre and Drive
                Inn's will have ticket sales as is traditional for those types
                of venues. A daily pass is not required for any of these areas.
                The City Parks and Sportsplex will require a small nominal fee
                per person for entry.
              </p>
            </li>
            <li>
              <h3>Q. Is the parking outside?</h3>
              <p>
                Yes and No. The parkades are located outside of the covered
                facility but are partially covered. DreamScape is connected to
                the parkades by way of a monorail train system. Essentially the
                monorail will transport guests into DreamScape and will provide
                shuttle service to and from the parkades.
              </p>
            </li>
            <li>
              <h3>Q. Can I park and walk right into DreamScape?</h3>
              <p>
                This will not be an available option for entry. Our guest’s
                safety is our main priority and proper security measures must be
                set in place. Each guest must go through a security checkpoint
                prior to boarding the monorail which will transport you to
                DreamScape.
              </p>
            </li>
            <li>
              <h3>Q. Is there a charge for the monorail service?</h3>
              <p>No charge and it will be an automated system.</p>
            </li>
            <li>
              <h3>
                Q. Will there be a design layout or design drawings of
                DreamScape that we will be able to view on the website?
              </h3>
              <p>
                At a later date we will have drawings and design layouts of
                DreamScape available on our website however, at this time, it is
                still a work in progress. We have however, started providing
                renderings, pictures and videos to illustrate the many wonderful
                features of DreamScape.
              </p>
            </li>
            <li>
              <h3>
                Q. What kind of stores and businesses are in the Retail Hub?
              </h3>
              <p>
                The Hub will house over 600 retail spaces and will include
                everything from traditional stores to local favourites, big box
                stores, designer and hard to find stores, office space, cinemas
                as well as food and beverage outlets just to name a few.
              </p>
            </li>
            <li>
              <h3>
                Q. Is the Drive-In movie theater located under the covered roof?
              </h3>
              <p>
                The Drive-In will be located within the climate controlled
                superstructure of DreamScape and will have retractable areas for
                seasonal open air. The Drive-In movie theaters will be opened
                year-round.
              </p>
            </li>
            <li>
              <h3>
                Q. So I will be able to go for a walk in the middle of winter in
                these City Size Parks?
              </h3>
              <p>
                Yes, the park is inside DreamScape climate control facility. You
                will be able to walk in shorts thru-out the park, stroll over
                the many bridges located over the ponds and waterways,
                rollerblade or just go for a run any time of the year.
              </p>
            </li>
            <li>
              <h3>Q. What is the purpose of the Amphitheatre?</h3>
              <p>
                The Amphitheatre is designed for the arts and culture -
                symphonies, live plays, concerts, the ballet etc. even
                professional exhibition basketball games. The floor system is
                retractable allowing for two practical floors and the roof is
                retractable.
              </p>
            </li>
            <li>
              <h3>Q. How many people does the Amphitheatre seat?</h3>
              <p>
                The Amphitheatre will be able to seat just over 20,000 people.
              </p>
            </li>
            <li>
              <h3>Q. How long will it take to build DreamScape One?</h3>
              <p>
                The full deployment of all phases of the project will take ten
                plus years to execute and complete before DreamScape is fully
                active and online.
              </p>
            </li>
            <li>
              <h3>Q. What is the Premium Space zone?</h3>
              <p>
                The Premium Space zone which we also call the Premium Space and
                Nightlife Zone is an area where all the major nightlife will be
                happening - bars, lounges, restaurants etc. This area is
                centralized in the middle of the facility and rises several
                stories high overlooking everything in DreamScape. It also gives
                first class box office seating from any of your favorite
                restaurants or lounges, looking onto the Amphitheatre stage.
              </p>
            </li>
            <li>
              <h3>
                Q. Are the Condominiums for rent and purchase? Could I live
                there?
              </h3>
              <p>
                Yes and Yes. You could buy a condominium or purchase a Time
                Share and rent it; however, it is still a work in progress. More
                details on this will be provided in the near future.
              </p>
            </li>
            <li>
              <h3>
                Q. Are you active on Social Media? Where can I follow you to
                receive more updates besides the website? How can I help and
                assist you with this project?
              </h3>
              <p>
                You could check us out on YouTube.com, Facebook, Twitter
                (@Dreamscape_204) and even Instagram (@dreamscapewinnipeg).
                DreamScape is a complex, one of a kind project that requires
                individuals with specific set of skills and background.
                Creativity, innovation and adaptability are some of the key
                assets we look for to join our team. Tell us about yourself and
                how you might be able to help us. We would love to hear from
                you.
              </p>
            </li>
          </ul>
          <p>
            Like Us on our Facebook page at{" "}
            <Link to="www.facebook.com/dreamscapewinnipeg">
              www.facebook.com/dreamscapewinnipeg
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};
