import React, { useState } from "react";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { Outlet } from "react-router-dom";

export const LandingPage = (prop) => {
  const [isActive, setIsActive] = useState("/");
  return (
    <>
      <Header selectedPage={isActive} />
      <Outlet context={[isActive, setIsActive]} />
      <Footer />
    </>
  );
};
