import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./swiper.css";
import { Pagination, Navigation, EffectFade, Autoplay } from 'swiper/modules';

export const AutoSlideshow = (props) => {
  return (
    <Swiper
      // effect={"fade"}
      // loop={true}
      autoplay={{
        delay: 4000,
      }}

      modules={[EffectFade, Pagination, Autoplay, Navigation]}
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      pagination={{
        clickable: true,
      }}
      // navigation={true}
      className="mySwiper"
    >
      {props.image.map((img, index) => {
        return (
          <SwiperSlide key={index}>
            <img src={img} alt={`hero ${index}`} />
          </SwiperSlide>
        )
      })
      }
    </Swiper>
  )
}
