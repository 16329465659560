import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/sportsplex.jpg";
import { useOutletContext } from "react-router-dom";
import image from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';
export const Skatingrink = () => {

  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="SPORTSPLEX" subText="The Sportsplex located within DreamScape will include basketball courts, baseball diamonds, skating rinks, soccer fields and our fitness Signature Building" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <h2>Sportsplex</h2>
          <p>
            The Sportsplex located within DreamScape will be the first of its kind in Manitoba and will surpass any complex like it in Canada. Year-round, you will have the opportunity to play baseball, soccer (on natural grass), basketball, ice skating, run, exercise, train, compete in mixed martial arts events and so much more. Getting into peak physical condition will seem a lot easier at this expansive fitness facility. We will keep you motivated in winter, with our beautiful landscaped scenery of green parks, trees, ponds, trails, bridges and waterfalls inside DreamScape’s climate controlled superstructure. You will want to come back and shake off those cold winter blues.
          </p>
          <p>
            Yoga, dancing and gymnastics will be some of the many programs offered in our spectacular Signature Glass building within DreamScape. Each floor is dedicated to a particular activity or sport with picturesque views from every vantage point. DreamScape will be the only facility in Manitoba to sustain maintenance free engineered real turf grass.
          </p>
          <p>
            The Sportsplex features:
          </p>
          <ul className="simple-list">
            <li>Open air basketball courts</li>
            <li>Soccer fields with natural grass</li>
            <li>Baseball fields with natural grass and dirt infields</li>
            <li>Beach volleyball courts</li>
            <li>Ice Skating rinks with available skate rentals, skating lessons</li>
            <li>A fitness facility with state of the art fitness equipment, cardio machines, free weights and tracks.</li>
            <li>Bleachers at all sporting events</li>
            <li>Ample dedicated parking for the Sportsplex</li>
            <li>Rental times for private events and schedules for open/public activities</li>
            <li>Opportunities to organize tournaments, fundraisers and private/community practices/games that would normally be seasonal</li>
          </ul>
          <p>We are proud to contribute to our community by providing a safe, enjoyable center for all ages, year-round.</p>
          <p>
            What’s your favorite sport you would like to see? Tell us, we would love to hear from you!</p>
        </div>
      </div>
    </>
  );
}


