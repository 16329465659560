import img1 from '../../assets/banner/hero/hero1.jpg';
import img2 from '../../assets/banner/hero/hero2.jpg';
import img3 from '../../assets/banner/hero/hero3.jpg';
import img4 from '../../assets/banner/hero/hero4.jpg';
import img5 from '../../assets/banner/hero/hero5.jpg';
import img6 from '../../assets/banner/hero/hero6.jpg';
import img7 from '../../assets/banner/hero/hero7.jpg';
import img8 from '../../assets/banner/hero/hero8.jpg';
import img9 from '../../assets/banner/hero/hero9.jpg';
import img10 from '../../assets/banner/hero/hero10.jpg';
import img11 from '../../assets/banner/hero/hero11.jpg';
import img12 from '../../assets/banner/hero/hero12.jpg';
import img13 from '../../assets/banner/hero/hero13.jpg';
import img14 from '../../assets/banner/hero/hero14.jpg';
import img15 from '../../assets/banner/hero/hero15.jpg';
import img16 from '../../assets/banner/hero/hero16.jpg';
import img17 from '../../assets/banner/hero/hero17.jpg';
import img18 from '../../assets/banner/hero/hero18.jpg';
import img19 from '../../assets/banner/hero/hero19.jpg';
import img20 from '../../assets/banner/hero/hero20.jpg';
import img21 from '../../assets/banner/hero/hero21.jpg';
import img22 from '../../assets/banner/hero/hero22.jpg';
import img23 from '../../assets/banner/hero/hero23.jpg';
import img24 from '../../assets/banner/hero/hero24.jpg';
import img25 from '../../assets/banner/hero/hero25.jpg';
import img26 from '../../assets/banner/hero/hero26.jpg';
import img27 from '../../assets/banner/hero/hero27.jpg';
import img28 from '../../assets/banner/hero/hero28.jpg';
import img29 from '../../assets/banner/hero/hero29.jpg';
import img30 from '../../assets/banner/hero/hero30.jpg';
import img31 from '../../assets/banner/hero/hero31.jpg';

export default [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31];