import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import { Link, useSearchParams } from "react-router-dom";
import "./header.css";

export const Header = (prop) => {
  const [showNavBar, setShowNavBar] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollingDown = prevScrollPos > currentScrollPos;
      setShowNavBar(
        currentScrollPos === 0 || scrollingDown || currentScrollPos < 70
      );

      setPrevScrollPos(currentScrollPos);

      // setShowNavBar(prevScrollPos > currentScrollPos || currentScrollPos === 0);
      // setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <>
    <header
      className={
        prevScrollPos > 0
          ? "container-style header-background"
          : "container-style"
      }
      style={{ top: showNavBar ? "0" : "-150px" }}
    >
      <div className="content-sizing header-wrapper">
        <Link to="/">
          <img src={logo} alt="Dreamscape Logo" />
        </Link>
        <nav className="header-nav">
          <div className="header-menu-text hide-phone">
            <h3>Global Destination</h3>
            <i className="bi bi-dot"></i>
            <h3>Privately Funded </h3>
            <i className="bi bi-dot"></i>
            <h3>Approx. 20,000 Jobs Pre/Post Construction</h3>
          </div>
          <ul>
            <li>
              <Link
                to="/"
                title="Project Summary"
                className={prop.selectedPage === "/" ? "active-menu-itm" : ""}
              >
                Project Summary
              </Link>
            </li>
            <li>
              <Link
                to="mission"
                title="Mission"
                className={
                  prop.selectedPage === "/mission" ? "active-menu-itm" : ""
                }
              >
                Mission
              </Link>
            </li>
            <li className="drop-down-container">
              <Link
                to="features"
                title="Unique Park Features"
                className={
                  prop.selectedPage === "/feature" ? "active-menu-itm" : ""
                }
              >
                Features <i className="bi bi-chevron-down rotate"></i>
              </Link>
              <ul className="drop-box">
                <li>
                  <Link to="hotels" title="Hotels">
                    Hotels
                  </Link>
                </li>
                <li>
                  <Link to="condo" title="Condominiums">
                    Intraurban Living
                  </Link>
                </li>
                <li>
                  <Link to="mall" title="Shopping Mall">
                    Retail Hub
                  </Link>
                </li>
                <li>
                  <Link to="amusement" title="Amusement Park">
                    Amusement Park
                  </Link>
                </li>
                <li>
                  <Link to="waterpark" title="Waterpark">
                    Water Park
                  </Link>
                </li>
                <li>
                  <Link to="winterpark" title="Winter Park">
                    Winter Park
                  </Link>
                </li>
                <li>
                  <Link to="theater" title="Ampitheater">
                    Amphitheatre
                  </Link>
                </li>
                <li>
                  <Link to="drivein" title="Drivein">
                    Drive-In Theatres
                  </Link>
                </li>
                <li>
                  <Link to="golf" title="Golf Course">
                    Golf Course
                  </Link>
                </li>
                <li>
                  <Link to="adultzone" title="Adult Zone">
                    Premium Space and Nightlife Zone
                  </Link>
                </li>
                <li>
                  <Link to="skatingrink" title="Skating Rink">
                    Sportsplex
                  </Link>
                </li>
                <li>
                  <Link to="green" title="Green Space">
                    Green Space
                  </Link>
                </li>
                <li>
                  <Link to="environment" title="Environmental">
                    Environmental/Climate Change
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="community"
                title="Community"
                className={
                  prop.selectedPage === "/community" ? "active-menu-itm" : ""
                }
              >
                Community
              </Link>
            </li>
            <li>
              <Link
                to="sponsors"
                title="Sponsors"
                className={
                  prop.selectedPage === "/sponsors" ? "active-menu-itm" : ""
                }
              >
                Sponsors
              </Link>
            </li>
            <li>
              <Link
                to="faq"
                title="Faq"
                className={
                  prop.selectedPage === "/faq" ? "active-menu-itm" : ""
                }
              >
                FAQ
              </Link>
            </li>
            <li>
              <Link
                to="engineering"
                title="Engineering"
                className={
                  prop.selectedPage === "/engineering" ? "active-menu-itm" : ""
                }
              >
                Engineering
              </Link>
            </li>
            <li>
              <Link
                to="visionary"
                title="Visionary"
                className={
                  prop.selectedPage === "/visionary" ? "active-menu-itm" : ""
                }
              >
                Visionary
              </Link>
            </li>
            <li>
              <Link
                to="location"
                title="Location"
                className={
                  prop.selectedPage === "/location" ? "active-menu-itm" : ""
                }
              >
                Location
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                title="Contact"
                className={
                  prop.selectedPage === "/contact" ? "active-menu-itm" : ""
                }
              >
                Contact
              </Link>
            </li>
          </ul>
          <button className="reponsive-button-nav" onClick={() => setOpenNav(true)} type="button"><i className="bi bi-list"></i></button>
        </nav>
      </div>
    </header>

    <div className={openNav ? "responsive-nav-bar-container open-res-nav" : "responsive-nav-bar-container close-res-nav"}>
      <button onClick={() => setOpenNav(false)}>Close <i className="bi bi-x-lg"></i></button>
      <ul>
        <li>
          <Link
            to="/"
            title="Project Summary"
            onClick={() => setOpenNav(false)}
            className={prop.selectedPage === "/" ? "active-menu-itm" : ""}
          >
            Project Summary
          </Link>
        </li>
            <li>
              <Link
                onClick={() => setOpenNav(false)}
                to="mission"
                title="Mission"
                className={
                  prop.selectedPage === "/mission" ? "active-menu-itm" : ""
                }
              >
                Mission
              </Link>
            </li>
            <li className="">
              <Link
                to="features"
                onClick={() => setOpenNav(false)}
                title="Unique Park Features"
                className={
                  prop.selectedPage === "/feature" ? "active-menu-itm" : ""
                }
              >
                Features <i className="bi bi-chevron-down rotate"></i>
              </Link>
              <ul className="drop-box-responsive">
                <li>
                  <Link to="hotels" title="Hotels">
                    Hotels
                  </Link>
                </li>
                <li>
                  <Link to="condo" title="Condominiums">
                    Intraurban Living
                  </Link>
                </li>
                <li>
                  <Link to="mall" title="Shopping Mall">
                    Retail Hub
                  </Link>
                </li>
                <li>
                  <Link to="amusement" title="Amusement Park">
                    Amusement Park
                  </Link>
                </li>
                <li>
                  <Link to="waterpark" title="Waterpark">
                    Water Park
                  </Link>
                </li>
                <li>
                  <Link to="winterpark" title="Winter Park">
                    Winter Park
                  </Link>
                </li>
                <li>
                  <Link to="theater" title="Ampitheater">
                    Amphitheatre
                  </Link>
                </li>
                <li>
                  <Link to="drivein" title="Drivein">
                    Drive-In Theatres
                  </Link>
                </li>
                <li>
                  <Link to="golf" title="Golf Course">
                    Golf Course
                  </Link>
                </li>
                <li>
                  <Link to="adultzone" title="Adult Zone">
                    Premium Space and Nightlife Zone
                  </Link>
                </li>
                <li>
                  <Link to="skatingrink" title="Skating Rink">
                    Sportsplex
                  </Link>
                </li>
                <li>
                  <Link to="green" title="Green Space">
                    Green Space
                  </Link>
                </li>
                <li>
                  <Link to="environment" title="Environmental">
                    Environmental/Climate Change
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="community"
                onClick={() => setOpenNav(false)}
                title="Community"
                className={
                  prop.selectedPage === "/community" ? "active-menu-itm" : ""
                }
              >
                Community
              </Link>
            </li>
            <li>
              <Link
                to="sponsors"
                onClick={() => setOpenNav(false)}
                title="Sponsors"
                className={
                  prop.selectedPage === "/sponsors" ? "active-menu-itm" : ""
                }
              >
                Sponsors
              </Link>
            </li>
            <li>
              <Link
                to="faq"
                title="Faq"
                onClick={() => setOpenNav(false)}
                className={
                  prop.selectedPage === "/faq" ? "active-menu-itm" : ""
                }
              >
                FAQ
              </Link>
            </li>
            <li>
              <Link
                to="engineering"
                title="Engineering"
                onClick={() => setOpenNav(false)}
                className={
                  prop.selectedPage === "/engineering" ? "active-menu-itm" : ""
                }
              >
                Engineering
              </Link>
            </li>
            <li>
              <Link
                to="visionary"
                onClick={() => setOpenNav(false)}
                title="Visionary"
                className={
                  prop.selectedPage === "/visionary" ? "active-menu-itm" : ""
                }
              >
                Visionary
              </Link>
            </li>
            <li>
              <Link
                to="location"
                title="Location"
                onClick={() => setOpenNav(false)}
                className={
                  prop.selectedPage === "/location" ? "active-menu-itm" : ""
                }
              >
                Location
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                onClick={() => setOpenNav(false)}
                title="Contact"
                className={
                  prop.selectedPage === "/contact" ? "active-menu-itm" : ""
                }
              >
                Contact
              </Link>
            </li>
        {/* <li><Link to="/" onClick={() => setOpenNav(false)}>Home</Link></li>
        <li><Link to="/about-us" onClick={() => setOpenNav(false)}>About Us</Link></li>
        <li><Link to="/services/general" onClick={() => setOpenNav(false)}>Services</Link></li>
        <li><Link to="/faq" onClick={() => setOpenNav(false)}>FAQ</Link></li> */}
      </ul>
    </div>
    </>
  );
};
