
import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-header-subtext/PageHeader'
import hero from "../../assets/banner/hero/hero23.jpg"
import { useOutletContext } from "react-router-dom";
import image from './image';
import { AutoSlideshow } from '../../components/Slideshow/AutoSlideshow';

export const Environment = () => {

  const [isActive, setIsActive] = useOutletContext();
  setIsActive("/community");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <PageHeader title="ENVIRONMENTAL/CLIMATE CHANGE" subText="" backgroundImage={hero} />
      <div className="single-text-box container-style ">
        <div className="content-sizing single-content-box">
          <p>
            Focused on our core objectives of environment and climate issues DreamScape (DS1) is a vision of the way forward for society based on renewable energy sources, sustainability and carbonless footprint. However, the theme for DreamScape is focused on entertainment, hospitality and retail as the revenue generators of this mega project.
          </p>
          <p>
            DreamScape is building the mega structures of the future with a business model based on everything under one roof, protected from the outside elements year-round.
          </p>
          <p>
            This is a NET ZERO CARBONLESS "state of the art" project, adapting to creative sustainable solutions for heating, cooling, water management, power and resource recycling. Working with engineers, consultants, specialists, manufacturers, suppliers as well as other professionals locally and internationally has moved this venture from an idea to a reality.
          </p>
          <h2>The Timing is Right</h2>
          <p>
            Technology has definitely changed the world we live in today and it will continue to impact our future. The need for sustainable developments and environmentally sound projects has never been greater. Currently, the world is in a phase of environmental uncertainty, and we are faced with one of the greatest challenges of our time to heal our planet and protect our ecology and ecosystems.
          </p>
          <p>
            DS1 has set its sight and focus on ‘building a better tomorrow’ as the world continues to grow. Challenged by the present day limitation on renewable energy, the need to utilize technology in a better way has never been greater. DreamScape is a vision of a way forward, creating the most ideal environment in a structure that has zero carbon footprint, and sustainable.
          </p>
          <p>
            DS1 has taken notice of the issues of climate change, the displacement of communities and lives globally, the environmental impact and its devastating effects. It addresses what many entrepreneurs, tech companies, philanthropists, and businesses have been lobbying for, the first major fix of our climate change concern with a revolutionary approach – a structure that can be built using next generation technology. The world has evolved to understand we are one on this planet that is ours to live in for generations to come, and we must treat it with the respect it deserves. We are the human race and now is the time in our history to collectively work together and fix our planet.
          </p>
        </div>
      </div>
    </>
  );
}


